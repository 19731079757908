import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { DesktopMain, MobileMain } from '../../components';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { getLocalizedDataFromContentfulEdges } from '../../utils';

const Newsroom = () => {
	const intl = useIntl();
	const { allContentfulSpecificPageMetaData } = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "newsroom" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
		}
	`);
	const { node } = getLocalizedDataFromContentfulEdges(allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	return (
		<>
			<SEO lang={intl.locale} title={node.title} description={node.description}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<DesktopMain />
				<MobileMain />
			</Layout>
		</>
	);
};

export default Newsroom;
